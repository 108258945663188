import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {Button, Grid, Link} from "@mui/material";
import Table from "../components/Table";
import TradingviewLink from "../components/TradingviewLink";
import HomepageUrl from "../components/HomepageUrl";
import _get from "lodash/get";
import {getEndpoint} from "../communication/rest";
import SectorAndIndustryInfo from "./SectorAndIndustryInfo";
import {formatDate} from "../util/Dates";


export default inject("stockDataStore")(observer(({stockDataStore, endpoint}) => {

  useEffect(() => {
    switch (endpoint) {
      case "stocks" : stockDataStore.listStocksByRs(); break;
      case "sectors" : stockDataStore.listSectorsByRs(); break;
      case "uptrending" : stockDataStore.listUptrendingStocks(); break;
    }

  }, []);

  const columns = [
    {
      field: 'symbol',
      headerName : "Symbol",
      width: 50,
      renderRowCell: (column, row) => {
        return <TradingviewLink symbol={row[column.field]} />
      }
    },
    {
      field: 'stock.webUrl',
      headerName : "Url",
      renderRowCell: (column, row) => {
        let value = _get(row, column.field)
        return <HomepageUrl url={value} />
      }
    },
    {
      field: 'stock.type.value',
      headerName : "Type",
      title: 'stock.type.description'
    },
    {
      field: 'stock.market.mic',
      headerName : "Exch",
      title: 'stock.market.description'
    },
    {
      field: 'stock.name',
      headerName : "Name",
      title: 'stock.description'
    },
    {
      field: 'stock.gicsSector.finvizName',
      headerName : "Sector",
      headerCategory: "GICS",
    },
    {
      field: 'stock.gicsIndustry.finvizName',
      headerCategory: "GICS",
      headerName : "Industry"
    },

    {
      field: 'stock.ipo',
      headerName : "IPO",
      type : 'date'
    },
    {
      field: 'stock.marketCap',
      headerCategory: "in Mio",
      headerName : "Market Cap.",
      type : 'integer',
      divider : 1000000
    },
    {
      field: 'stock.sharesOutstanding',
      headerCategory: "in Mio",
      headerName : "Shares Outst.",
      type : 'integer',
      divider : 1000000
    },
    {
      field: 'stock.totalEmployees',
      headerName : "Empl",
      type : 'integer',

    },
    {
      field: 'rsRank',
      headerName : "RS",
      type : 'integer',
      divider : 0.01
    },
    {
      field: 'close',
      headerName : "Price",
      type : 'money'
    },
    {
      field: 'changePercent',
      headerName : "Chg",
      type : 'percent'
    },
    {
      field: 'volume',
      headerName : "Volume",
      type : 'integer'
    }
  ]

  const dataList = toJS(stockDataStore.stockdata)
  return (

    <Grid>

      <h2>{endpoint} from {dataList.length > 0 ? formatDate(dataList[0].date) : ''} ({dataList.length}) </h2>

      <div>
        <Link href={getEndpoint() + "stockdata/rs/stocks/export?format=tradingview"}>Download</Link>
        <Button onClick={() => stockDataStore.updateRs()}>Update</Button>
      </div>

      <SectorAndIndustryInfo stockdata={dataList} />
      <br />

      <Table
        columns={columns}
        rows={dataList}
      />

    </Grid>
  )
}))

